.AppSectionTitle {
  color: "black";
  font-size: 20px;
}
.AppSectionSubTitle {
  color: "gray";
}

.TagLine {
  color: rgb(30, 30, 30);
  font-size: 3.5vw;
}

.ProductDetailsHolder {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 900px;
  height: 100%;
  z-index: 9998;
  background-color: transparent;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.ProductDetailsHolder::-webkit-scrollbar {
  display: none;
}
.ProductDetailsMainView {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 40px;
}

.BottomMenuHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  margin-top: 45px;
}

.CardsHolder {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 15px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
.Card {
  height: 210px;
  padding: 25px;
  padding-left: 20px;
  border-radius: 8px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
  cursor: pointer;
  position: relative;
}
.Card:hover {
  box-shadow: 0px 0px 10px 0 rgba(180, 180, 180, 0.7);
  border-width: 0px;
}
.CardLogo {
  /* width: 150px; */
  height: 35px;
  object-fit: contain;
  margin-bottom: 5px;
}
.div_CardTitle {
  margin-top: -10px;
}
.div_CardSubTitle {
  margin-top: -18px;
}
.CardSubTitle {
  font-size: 12px;
  color: darkgray;
}

.div_CardDescription {
  margin-top: 15px;
}
.CardDescription {
  font-size: 13px;
  color: dimgray;
}

.SearchBarLeftSection {
  width: 100%;
}

.LandingSearchBar {
  margin-top: 0px;
  /* height: 70px; */
  width: 100%;
  background-color: white;
  border-radius: 13px;
  border-color: rgb(245, 245, 245);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
  padding: 12px;
  box-sizing: border-box;
}

.InfoGraphicsBanner {
  margin-top: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.InfoGraphicsBannerCard {
  position: relative;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding-bottom: 10px;
}
.InfoGraphicsBannerCardImage {
  height: 70%;
  width: 100%;
  border-start-start-radius: 20;
  border-end-start-radius: 20;
}

.LandingMenuBar {
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
}

.BecomeParter {
  background-image: linear-gradient(
    to right,
    rgb(245, 55, 45),
    rgb(210, 55, 80)
  );
  padding: 30px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  /* height: 70px; */
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedGroup {
  color: rgb(0, 120, 200);
  border-left: 3px solid #0078c8;
}
.all-app-title {
  cursor: pointer;
}
.all-app-title:hover {
  color: rgb(0, 120, 200);
}
.groupClassWrapper:hover {
  color: rgb(0, 120, 200);
}
input[type="text"] {
  width: 100%;
  border: 1px solid #fff;
  font-size: 14px;
}
input:focus {
  outline: none;
}
.download-store {
  cursor: pointer;
}
.download-store:hover {
  box-shadow: 0px 0px 10px 0 rgba(180, 180, 180, 0.7);
}

.container {
  padding-left: 0px;
  padding-right: 0px;
  /* margin-top: 30px; */
  background-color: white;
}
.container2 {
  position: absolute;
  width: 90%;
  left: 50%;
  /* top: 0.8%; */
  transform: translate(-50%, 0.8%);
}

.leftMenuHolder {
  width: 20%;
}
.leftMenu {
  width: 100%;
}
.appListholder {
  width: 80%;
}

@media only screen and (max-width: 1200px) {
  .CardsHolder {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .CardsHolder {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .leftMenuHolder {
    width: 0px;
    overflow: hidden;
  }
  .leftMenu {
    width: 0px;
    overflow: hidden;
  }
  .appListholder {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .SearchBarLeftSection {
    width: 0%;
    overflow: hidden;
    height: 0px;
  }
  .container2 {
    width: 97%;
  }
}

@media only screen and (max-width: 400px) {
  .CardsHolder {
    grid-template-columns: repeat(1, 1fr);
  }
}
