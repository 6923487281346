.ProductInfoText {
  font-size: 13px;
  color: gray;
  width: 100%;
}

.StoreImage {
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: solid 0.5px rgb(200, 200, 200);
  overflow: hidden;
}
.StoreThumb {
  width: 50px;
  height: 35px;
  padding: 5px;
  border: solid 1px rgb(230, 230, 230);
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.StoreThumbHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
}

.ProductInfoColumn {
  height: 100%;
  width: 34%;
  vertical-align: top;
  background-color: rgb(245, 245, 245);
  border-radius: 12px;
  padding: 20px;
}
.InfoView {
  width: 100%;
}
.ProductDescriptionView {
  width: 70%;
}
.GetAppButtonTopView {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.module-title {
  font-weight: 400 !important;
  font-size: 24px !important;
}
.module-sub-title {
  font-weight: 300 !important;
  font-size: 20px !important;
}
.key-benefits {
  padding-left: 20px !important;
}
.key-benefits li {
  font-size: 14px !important;
  line-height: 30px !important;
}
.ComingSoonButton {
  background-image: linear-gradient(to right, red, rgb(210, 55, 80));
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 11px;
  color: white;
  font-size: 14px;
  border: none;
  font-weight: bold;
  margin-right: 10px;
  outline: 0;
}
.app-header p,
li {
  font-size: 14px !important;
  line-height: 30px !important;
}

@media only screen and (max-width: 600px) {
  .ProductInfoColumn {
    width: 0px;
    overflow: hidden;
    background-color: transparent;
    padding: 0px;
  }
  .InfoView {
    width: 0px;
    overflow: hidden;
  }
  .ProductDescriptionView {
    width: 100%;
  }
  .GetAppButtonTopView {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
