@font-face {
  font-family: roboto_regular;
  src: url("../../../assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: roboto_medium;
  src: url("../../../assets/fonts/Roboto/Roboto-Medium.ttf");
}

h2 {
  font-family: roboto_medium;
  color: rgb(60, 60, 60);
}
h3 {
  font-family: roboto_medium;
  color: rgb(60, 60, 60);
}
h4 {
  font-family: roboto_medium;
}
text {
  font-family: roboto_medium;
}
subText {
  font-family: roboto_regular;
}

.checked {
  color: orange;
  font-size: 14px;
}

.TransparentBackground {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.5);
}

.NewTag {
  padding-left: 4px;
  padding-right: 4px;
  height: 20px;
  background-color: rgb(235, 76, 96);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.GradientButton {
  background-image: linear-gradient(to right, red, rgb(210, 55, 80));
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 11px;
  color: white;
  font-size: 14px;
  border: none;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  outline: 0;
}

.BorderedButton {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  border: solid;
  border-width: 1px;
  border-color: rgba(180, 180, 180, 1);
  margin-right: 10px;
  cursor: pointer;
  outline: 0;
}

.HeaderMenuItem {
  background-color: white;
  color: rgb(50, 50, 50);
  font-size: 15px;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
}
.HeaderMenuItem:hover {
  color: red;
}
